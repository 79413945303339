import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useManaWallet } from "../custom-providers/useWallet"
import { getGamesPlayed, setError, setLoading, setSuccess } from "../actions";
import Accept from "../assets/Accept";
import Cancel from "../assets/Cancel";
import Clock from "../assets/Clock";
import Game from "../assets/Game";
import Price from "../assets/Price";
import {
  findChallenge,
  cancelChallenge,
  acceptChallenge,
  setWinnerPlayer,
} from "../services/concordium.service";
import moment from "moment";
import NavBar from "../components/NavBar";
import SideBar from "../components/SideBar";
import challengeService from "../services/challenge.service";
import SelectInput from "../components/SelectInput";
import ccd from "../assets/ccd2.png";

const SingleChallenge = ({ navbarSize, user, gamerTag }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [active, setActive] = useState("invite");
  const [myScore, setMyScore] = useState("");
  const [winner, setWinner] = useState("");
  const [challengeBlockchain, setChallengeBlockchain] = useState({});
  const { coinToken, walletAddress, isWalletConnected, walletConnectionActive, binanceContract, displayCoinToken } = useManaWallet();

  const getChallenge = async (withRefreshBlockchain = false) => {
    dispatch(setLoading(true));
    let res = await challengeService.ChallengesById(id, withRefreshBlockchain);
    if (res.success) {
      setData(res.data);
      try {
        if (res.data.coinToken === "CDC") {
          const challengeBlockchain = await findChallenge(id);
          setChallengeBlockchain(challengeBlockchain);
        } else if (res.data.coinToken === "BNB" || res.data.coinToken === "MM") {
          // console.log(binanceContract.__private_0_provider._network.chainId) //Not sure about this... I made some testing and the function to detect chain changes does not seems to work, but I don't know if no being in the correct net can bring problems... There needs to be some testing of all kind of situations no know for sure.
          const responseBNB = await binanceContract.getChallenge(id)
          setChallengeBlockchain(responseBNB);
        }
      } catch (e) {
        dispatch(setError(e.message));
      }
    } else {
      dispatch(setError(res.data.message));
    }
    dispatch(setLoading(false));
    dispatch(getGamesPlayed());
  };
  const accept = async () => {
    // we check if required wallet connect if not show a error
    if (!isWalletConnected && data.coinToken === coinToken) {
      dispatch(setError(`Please connect your ${coinToken} wallet`));
      return;
    } else if (isWalletConnected && data.coinToken !== coinToken) {
      dispatch(setError(`Please connect your ${data.coinToken} wallet`));
      return;
    }
    let hasGame = gamerTag.filter((res) => res.game === data.game._id);
    if (hasGame.length === 0) {
      dispatch(
        setError(
          "You can not join to this challenge because you do not have a gamer tag for this game"
        )
      );
      return;
    }
    dispatch(setLoading(true));
    // we check if the challenge is concordium blockchain
    if (data.coinToken === "CDC") {
      try {
        await acceptChallenge(
          walletAddress,
          walletConnectionActive.client,
          data.amount,
          data._id
        );
      } catch (e) {
        dispatch(setLoading(false));
        console.error(e);
        dispatch(setError("Something went wrong"));
        return;
      }
    } else if (data.coinToken === "BNB" || data.coinToken === "MM") {
      try {
        await binanceContract.acceptChallenge(data._id, data.amount.toString())
      } catch (e) {
        dispatch(setLoading(false));
        console.error(e);
        dispatch(setError("Something went wrong"));
        return;
      }
    }
    let res = await challengeService.AcceptChallenge(data._id, walletAddress);
    dispatch(setLoading(false));
    if (res.success) {
      dispatch(setSuccess("Challenge accepted"));
      getChallenge();
    } else {
      dispatch(setError(res.data.message));
    }
  };
  const reject = async () => {
    dispatch(setLoading(true));
    let res = await challengeService.RejectChallenge(data._id);
    dispatch(setLoading(false));
    getChallenge();
    if (res.success) {
      dispatch(setSuccess("Challenge rejected"));
    } else {
      dispatch(setError(res.data?.message));
    }
  };
  const checkPublic = () => {
    let res = data.challenged.filter((it) => it._id === user._id);
    if (res.length > 0) {
      return false;
    }
    return true;
  };
  const getUserPosition = () => {
    let res = data.challenged.findIndex((it) => it._id === user._id);
    return res;
  };
  const saveResult = async () => {
    // we check if required wallet connect if not show a error
    if (!isWalletConnected || (isWalletConnected && data.coinToken !== coinToken)) {
      dispatch(setError(`Please connect your ${isWalletConnected ? data.coinToken : coinToken} wallet`));
      return;
    }
    dispatch(setLoading(true));

    switch (data.coinToken) {
      case "MNG":
        const res = await challengeService.addResult(id, winner, myScore);
        dispatch(setLoading(false));
        if (res.success) {
          console.log(res.data)
          dispatch(setSuccess("Winner saved successfully!!!"));
          getChallenge();
          setActive("invite");
        } else {
          dispatch(setError(res.data.message));
          console.log(res.data)
          console.log(res.data.message)
        }
        break;
      case "CDC":
        const winnerAddress =
          data.creator._id === winner
            ? data.addressWalletCreator
            : data.challenged.find((it) => it._id === winner).addressWallet;
        const addressOfWinner = winnerAddress;
        try {
          await setWinnerPlayer(
            walletAddress,
            walletConnectionActive.client,
            addressOfWinner,
            data._id
          );
          const res = await challengeService.addResult(id, winner, myScore);
          if (res.success !== true) {
            dispatch(setError(res.data.message));
          }
        } catch (e) {
          dispatch(setLoading(false));
          console.error(e);
          dispatch(setError(e.message));
          return;
        }
        await getChallenge(true);
        dispatch(setSuccess("Winner saved successfully!!!"));
        setActive("invite");
        dispatch(setLoading(false));
        break;
      case "BNB":
        const winnerAddressBNB =
          data.creator._id === winner
            ? data.addressWalletCreator
            : data.challenged.find((it) => it._id === winner).addressWallet;
        const addressOfWinnerBNB = winnerAddressBNB;
        try {
          await binanceContract.setResultPlayer(data._id, addressOfWinnerBNB)
          const res = await challengeService.addResult(id, winner, myScore);
          if (res.success !== true) {
            dispatch(setError(res.data.message));
          }
        } catch (e) {
          dispatch(setLoading(false));
          console.error(e);
          dispatch(setError(e.message));
          return;
        }
        await getChallenge(true);
        dispatch(setSuccess("Winner saved successfully!!!"));
        setActive("invite");
        dispatch(setLoading(false));
        break;

      case "MM":
        const winnerAddressMM =
          data.creator._id === winner
            ? data.addressWalletCreator
            : data.challenged.find((it) => it._id === winner).addressWallet;
        const addressOfWinnerMM = winnerAddressMM;
        try {
          await binanceContract.setResultPlayer(data._id, addressOfWinnerMM)
          const res = await challengeService.addResult(id, winner, myScore);
          if (res.success !== true) {
            dispatch(setError(res.data.message));
          }
        } catch (e) {
          dispatch(setLoading(false));
          console.error(e);
          dispatch(setError(e.message));
          return;
        }
        await getChallenge(true);
        dispatch(setSuccess("Winner saved successfully!!!"));
        setActive("invite");
        dispatch(setLoading(false));
        break;
      default:
        console.error("There should not be an error here: ", data.coinToken)
        break;
    }
  };
  const cancel = async () => {
    // we check if required wallet connect if not show a error
    if (!isWalletConnected && data.coinToken === coinToken) {
      dispatch(setError(`Please connect your ${coinToken} wallet`));
      return;
    } else if (isWalletConnected && data.coinToken !== coinToken) {
      dispatch(setError(`Please connect your ${data.coinToken} wallet`));
      return;
    }
    dispatch(setLoading(true));
    // we check if the challenge is concordium blockchain
    if (data.coinToken === "CDC") {
      try {
        await cancelChallenge(
          walletAddress,
          walletConnectionActive.client,
          data._id
        );
      } catch (e) {
        dispatch(setLoading(false));
        console.error(e);
        dispatch(setError(e.message));
        return;
      }
    } else if (data.coinToken === "BNB" || data.coinToken === "MM") {
      try {
        await binanceContract.cancelChallenge(data._id)
      } catch (e) {
        dispatch(setLoading(false));
        console.error(e);
        dispatch(setError(e.message));
        return;
      }
    }
    let res = await challengeService.cancel(id);
    dispatch(setLoading(false));
    if (res.success) {
      dispatch(setSuccess("Challenge has been cancelled"));
      getChallenge();
    } else {
      if (res.data.message === "the challenge can't cancel") {
        dispatch(setError("The challenge already started, you can't cancel it")); 
      } else {
        dispatch(setError(res.data.message));
      }
    }
  };
  const isReadyToSetWinner = () => {
    if (checkChallengers() <= 0) {
      return false;
    }
    if (data?.coinToken === "CDC" && challengeBlockchain.winner?.None?.length === 0) {
      return true;
    } else if (data?.coinToken === "CDC" && challengeBlockchain.winner?.None?.length > 1) {
      return false;
    }
    if (data?.status === "playing") {
      return true;
    }
    return false;
  }
  useEffect(() => {
    getChallenge();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coinToken]);

  const checkChallengers = () => {
    let total = 0;
    if (data.isPublic) {
      total = data.challenged.length;
    } else {
      total = 0;
      data.challenged.forEach((element) => {
        if (element._id === data.creator._id) {
          total++;
        } else {
          if (element.isChallengeAccepted) {
            total++;
          }
        }
      });
    }
    return total;
  };
  const getWinner = () => {
    const challengerWinner = data.challenged.find((user) => user._id === data?.winner);
    return challengerWinner;
  }
  return (
    <>
      <NavBar />
      <SideBar />
      <div
        className={
          navbarSize === "small" ? "custom-container2" : "custom-container"
        }
      >
        {data !== null ? (
          <div className="container-fluid single-content">
            <div className="row header-tab-title">
              <div className="col-md-8">
                <h4 className="subtitle">Challenge</h4>
                <h2 className="title">
                  {data.name}
                  {typeof data.coinToken === "string"
                    ? " - " + data.coinToken
                    : ""}
                </h2>
              </div>
              <div className="col-md-4">
                {isWalletConnected && data.coinToken === displayCoinToken(coinToken) ? (
                  <div>
                    <h6
                      className="text-white"
                      style={{
                        textAlign: "center",
                        marginTop: "15px",
                        marginBottom: "15px",
                      }}
                    >
                      This Challenge Uses {` ${displayCoinToken(data.coinToken)} `}
                    </h6>
                  </div>
                ) : (
                  <div>
                    <h6
                      className="text-white"
                      style={{
                        textAlign: "center",
                        marginTop: "15px",
                        marginBottom: "15px",
                      }}
                    >
                      This Challenge Uses {` ${displayCoinToken(data.coinToken)} `} You Are using {` ${displayCoinToken(coinToken)}`}
                    </h6>
                  </div>
                )}
                {user._id === data.creator._id && data.status === "pending" ? (
                  <div className="col-md-12 text-center">
                    <button className="btn-pink-op" onClick={cancel}>
                      Cancel Challenge <Cancel />
                    </button>
                  </div>
                ) : null}
              </div>
              {user._id !== data.creator._id &&
                getUserPosition() >= 0 &&
                !data.challenged[getUserPosition()].isChallengeAccepted &&
                (data.status === "pending" || data.status === "accepted") ? (
                <div className="col-md-4">
                  <button className="btn-pink-op" onClick={reject}>
                    Decline Challenge <Cancel />
                  </button>
                  <button className="btn-green-op" onClick={accept}>
                    Accept Challenge <Accept />
                  </button>
                </div>
              ) : null}
              {data.isPublic && checkPublic() ? (
                <>
                  {(data.status !== "finalized") &
                    (data.status !== "expired") ? (
                    <div className="col-md-4">
                      <button className="btn-green-op" onClick={accept}>
                        Accept Challenge <Accept />
                      </button>
                    </div>
                  ) : null}
                </>
              ) : null}
            </div>
            <div className="row">
              <div className="col-md-12">
                <div
                  className="description"
                  dangerouslySetInnerHTML={{ __html: data.description }}
                ></div>
              </div>
            </div>
            <div className="row bg-gray">
              <div className="col-md-12 d-flex">
                <div className="challenge-info orange">
                  {data.coinToken === "CDC" ? (
                    <img src={ccd} alt="concordium" style={{ width: 20 }} />
                  ) : data.coinToken === "BNB" || data.coinToken === "MM" ? (
                    "BNB"
                    // <img src={bnbImage} alt="BNB" style={{ width: 20, background: "red" }} />
                  ) : (
                    <Price color="#FF8547" />
                  )}
                  {"  "}{data.amount}
                  <span>Total Prize Pool</span>
                </div>
                <div className="challenge-info blue">
                  <Clock />
                  {moment(data.startDate).format(
                    "DD MMM hh:mm a"
                  )}{" "}
                  (EST)
                  <span>Challenge Start</span>
                </div>
                <div className="challenge-info green">
                  <Game /> Game
                  <span>{data.game.gameName}</span>
                </div>
              </div>
            </div>
            <div className="row">
              {getWinner() ? (
                <div className="mt-4 mb-4 col-md-12">
                  <div className="winner">
                    <div className="winner-title">
                      <h4 className="subtitle">Winner</h4>
                      <h2 className="title">
                        {getWinner()?.userName}
                      </h2>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="col-md-6">
                <div className="tab">
                  <div className="tab-header">
                    <span
                      className={active === "invite" ? "active" : null}
                      onClick={() => setActive("invite")}
                    >
                      Invited People
                    </span>
                    {isReadyToSetWinner() === true ? (
                      <>
                        {getUserPosition() >= 0 &&
                          !data.challenged[getUserPosition()].myScore ? (
                          <span
                            className={active === "add" ? "active" : null}
                            onClick={() => setActive("add")}
                          >
                            Add Results
                          </span>
                        ) : null}
                      </>
                    ) : null}
                    {data.resultWinner ? (
                      <span
                        className={active === "match" ? "active" : null}
                        onClick={() => setActive("match")}
                      >
                        Match Results
                      </span>
                    ) : null}
                  </div>
                  <div
                    className={`tab-body ${active !== "invite" ? "hide" : ""}`}
                  >
                    {data.challenged.map((item, index) => (
                      <div className="C-P-list-Item" key={index}>
                        <div>
                          <img
                            alt="user"
                            className="C-P-list-Item-Image"
                            src={
                              item.urlPhoto
                                ? item.urlPhoto
                                : `https://getstream.io/random_svg/?name=${item.userName}`
                            }
                          />
                        </div>
                        <div className="C-P-list-Item-txt-container">
                          <div className="C-P-list-Item-txt">
                            <div className="C-P-list-Item-tv">
                              <div className="C-P-list-txt-title">
                                <div className="C-P-list-username">
                                  {item.gamerTag}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {data.creator._id === item._id ? (
                          <div
                            className="btn-green-op"
                            style={{ fontSize: 12 }}
                          >
                            Accepted
                          </div>
                        ) : (
                          <div
                            className={`${item.isRejected
                              ? "btn-pink-op"
                              : item.isChallengeAccepted
                                ? "btn-green-op"
                                : "btn-orange-op"
                              }`}
                            style={{ fontSize: 12 }}
                          >
                            {item.isRejected
                              ? "Rejected"
                              : item.isChallengeAccepted
                                ? "Accepted"
                                : "Invited"}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                  <div className={`tab-body ${active !== "add" ? "hide" : ""}`}>
                    <div className="containerlabel">
                      <div className="ST-inputs-title" style={{ fontSize: 14 }}>
                        My Score
                      </div>
                    </div>
                    <div
                      className="ST-inputs-container"
                      style={{ marginBottom: 20 }}
                    >
                      <input
                        type="text"
                        placeholder="Enter your SCORE"
                        className="ST-input-l-c"
                        value={myScore}
                        onChange={({ target }) => setMyScore(target.value)}
                      />
                    </div>
                    {myScore !== "" ? (
                      <SelectInput
                        options={data.challenged}
                        label="Select Winner"
                        name="games"
                        optionLabel="gamerTag"
                        optionValue="_id"
                        onChange={(val) => setWinner(val)}
                      />
                    ) : null}
                    <button className="btn-green-op" onClick={saveResult}>
                      Submit
                    </button>
                  </div>
                  <div
                    className={`tab-body ${active !== "match" ? "hide" : ""}`}
                  >
                    <div className="containerlabel">
                      <div className="ST-inputs-title" style={{ fontSize: 14 }}>
                        Results
                      </div>
                    </div>
                    <ul
                      style={{
                        color: "white",
                        paddingLeft: 0,
                        listStyle: "none",
                      }}
                    >
                      {data.challenged.map((item, indx) => (
                        <li key={indx} style={{ marginBottom: 10 }}>
                          <img
                            alt="user"
                            className="C-P-list-Item-Image"
                            src={
                              item.urlPhoto
                                ? item.urlPhoto
                                : `https://getstream.io/random_svg/?name=${item.userName}`
                            }
                          />{" "}
                          {item.gamerTag} <b>{item.myScore}</b>{" "}
                          {item.winner === item._id ? (
                            <i
                              className="fa fa-trophy"
                              style={{ color: "#7bed9f" }}
                            />
                          ) : null}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 img-content">
                <img
                  src={data.urlImage}
                  className="img imgChallenge"
                  alt="pict"
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    navbarSize: state.alerts.navbarSize,
    challenges: state.data.challenges,
    gamerTag: state.data.gamerTags,
    user: state.auth.user,
  };
};
export default connect(mapStateToProps)(SingleChallenge);