import { BrowserProvider, Contract } from "ethers";
import Abi from "../assets/contracts/ManaPassportContractBuild.json";
const browserProvider = new BrowserProvider(window.ethereum);
const zkContractAddress = "0x44f3812d6b28145c7a6ad6e23df8bef3c039df28";

const transferNft = async (nftIds, recipientAddress) => {
  if (!Array.isArray(nftIds)) {
    throw new Error("nftIds must be an array.");
  }

  try {
    if (recipientAddress) {
      const to = recipientAddress;
      const signer = await browserProvider.getSigner();
      const from = await signer.getAddress();
      const nftContract = new Contract(zkContractAddress, Abi.abi, signer);
      await Promise.all(
        nftIds.map(async (id) => {
          const tx = await nftContract.transferFrom(from, to, id);
          await browserProvider.waitForTransaction(tx.hash);
        })
      );
      return true;
    } else {
      console.log("Please insert a wallet address");
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export default transferNft;
