import React, { useEffect, useState } from "react";
import "../assets/styles/Wallet.css";
import { connect } from "react-redux";
import { Card, Col, Row } from "react-bootstrap";
import { PanelWallet } from "./wallet/PanelWallet";
import { FaCopy } from "react-icons/fa";
import { IoMdAddCircle } from "react-icons/io";
import { AiFillMinusCircle } from "react-icons/ai";
import { getData, postData } from "../apis";
import AlertUser from "../Alerts/userAlerts";
import { convertHash } from "./wallet/convertHash";
import { useManaWallet } from "../custom-providers/useWallet";
const Wallet = ({ wallet }) => {
  const [dataManaWallet, setdataManaWallet] = useState();
  const [showWithdraw, setshowWithdraw] = useState(false);
  const [walletAddress, setwalletAddress] = useState();
  const [amount, setamount] = useState();
  const [showDeposit, setshowDeposit] = useState(false);
  const {
    coinToken,
    walletAddress: navbarAddress,
    walletBalance,
  } = useManaWallet();
  const functionWithdraw = () => {
    showWithdraw === false ? setshowWithdraw(true) : setshowWithdraw(false);
    setshowDeposit(false);
  };

  const functionDeposit = () => {
    showDeposit === false ? setshowDeposit(true) : setshowDeposit(false);
    setshowWithdraw(false);
  };

  const dataWallet = React.useCallback(async () => {
    const resp = await getData("/v1/wallet?coinToken=MNG");
    if (resp) {
      setdataManaWallet(resp.data);
    }
  });

  const generateTransaction = async () => {
    const payload = {
      amount: `${amount}.00`,
      toAddress: walletAddress,
      coinToken: "MNG",
    };

    if (amount > dataManaWallet.balance || amount === 0) {
      AlertUser.insufficientBalance();
    } else {
      const data = await postData("/v1/wallet/to-external", payload);
      if (data) {
        AlertUser.succesfulTransaction();
        setshowWithdraw(false);
      }
    }
  };

  useEffect(() => {
    dataWallet();
  }, []);

  return (
    <div className="Container">
      <div className="row ">
        <div
          className="col-sm-12 col-md-12 col-lg-5 d-flex justify-content-center"
          style={{ flexDirection: "column" }}
        >
          <Card className="card-wallet">
            <Card.Body>
              <div className="card-address-btns">
                <div className="address">
                  <span id="address" className="addressAccount">
                    {dataManaWallet && coinToken === "MNG"
                      ? convertHash(dataManaWallet.address)
                      : convertHash(navbarAddress)}
                  </span>
                  <FaCopy
                    onClick={() => navigator.clipboard.writeText(navbarAddress)}
                    className="icon-copy"
                  />
                </div>

                <div className="containerCurrentBalance">
                  <div className="currenceBalanceC">
                    {coinToken === "QORBI" ? (
                      <p className="currenceBalance">QORBI Balance</p>
                    ) : (
                      <p className="currenceBalance">MNG Balance</p>
                    )}
                    <div className="flow">
                      {coinToken === "ZK" ? (
                        <p className="currenceBalance">ZK = {walletBalance}</p>
                      ) : (
                        <p className="flowBalance">
                          MNG = {dataManaWallet ? dataManaWallet.balance : null}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {coinToken === "MNG" ? (
                <Row className="section-btns-card mt-4">
                  <Col onClick={() => functionDeposit()}>
                    <div className="btn">
                      <IoMdAddCircle className="icon-btn" />
                      <div className="deposit"> Coming Soon</div>
                    </div>
                  </Col>
                  <Col>
                    {/*onClick={() => functionWithdraw()} */}
                    <div className="btn">
                      <AiFillMinusCircle className="icon-btn" />
                      <div className="history">Coming Soon</div>
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row className="section-btns-card mt-4">
                  <Col
                    style={{
                      opacity: 0.5,
                      cursor: "not-allowed",
                      pointerEvents: "none",
                    }}
                  >
                    <div className="btn">
                      <IoMdAddCircle
                        className="icon-btn"
                        style={{ color: "gray" }}
                      />
                      <div className="deposit">Coming Soon</div>
                    </div>
                  </Col>
                  <Col
                    style={{
                      opacity: 0.5,
                      cursor: "not-allowed",
                      pointerEvents: "none",
                    }}
                  >
                    <div className="btn">
                      <AiFillMinusCircle
                        className="icon-btn"
                        style={{ color: "gray" }}
                      />
                      <div className="history">Coming Soon</div>
                    </div>
                  </Col>
                </Row>
              )}

              {showWithdraw === true ? (
                <>
                  {" "}
                  <Row className="addresss">
                    <Col>
                      <input
                        type="text"
                        placeholder="Type Your Blockchain Address"
                        className="blockchainAddress"
                        onChange={(e) => {
                          setwalletAddress(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="addresss">
                    <Col>
                      <input
                        type="number"
                        placeholder="Amount"
                        className="blockchainAddress"
                        onChange={(e) => {
                          setamount(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>{" "}
                </>
              ) : null}

              {showDeposit === true ? (
                <>
                  <div className="container-P">
                    <p style={{ paddingTop: "1rem" }}>
                      just have to send mana token to:
                      <p
                        className="hashInternalWallet"
                        onClick={() =>
                          navigator.clipboard.writeText(dataManaWallet.address)
                        }
                      >
                        {" "}
                        {dataManaWallet.address}
                      </p>
                    </p>
                  </div>

                  {/* <Row className="addresss">
                    <Col>
                      <input
                        type="text"
                        placeholder="Type Your Blockchain Address"
                        className="blockchainAddress"
                        onChange={(e) => {
                          setdepositWalletAddress(e.target.value);
                        }}
                      />
                    </Col>
                  </Row> */}
                  {/* <Row className="addresss">
                    <Col>
                      <input
                        type="number"
                        placeholder="Amount"
                        className="blockchainAddress"
                        onChange={(e) => {
                          setamountDeposit(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>{" "} */}
                </>
              ) : null}
            </Card.Body>
          </Card>
          {showWithdraw === true ? (
            <button
              className="send"
              disabled={
                (amount !== undefined) &
                  (walletAddress !== "") &
                  (walletAddress !== undefined)
                  ? false
                  : true
              }
              onClick={() => generateTransaction()}
            >
              Send
            </button>
          ) : null}
          {/* {showDeposit === true ? (
            <button
              className="send"
              disabled={
                (amountDeposit !== undefined) &
                (depositWalletAddress !== "") &
                (depositWalletAddress !== undefined)
                  ? false
                  : true
              }
              onClick={() => deposit()}
            >
              Deposit
            </button>
          ) : null} */}
        </div>
        <div className="col-sm-12 col-md-12 col-lg-7">
          <PanelWallet />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return { wallet: state.wallet };
};
export default connect(mapStateToProps)(Wallet);
