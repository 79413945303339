/* eslint-disable react-hooks/exhaustive-deps */
/* global BigInt */
import "../assets/styles/Nav-bar.css";
import User from "./../assets/user2.jpg";
import NotificationsActiveOutlinedIcon from "@material-ui/icons/NotificationsActiveOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import friendService from "../services/friend.service";
import Notifications from "./Notifications";
import Home from "../assets/Home";
import ArrowDown from "../assets/ArrowDown";
import S3 from "../services/Aws/s3";
import userService from "../services/users";
import AlertUser from "../Alerts/userAlerts";
import React, { useEffect, useState, useCallback, useRef } from "react";
// import { Link } from "react-router-dom";
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    ModalHeader,
    ModalBody,
    Modal,
} from "reactstrap";
import { connect, useDispatch } from "react-redux";
import {
    setError,
    setNavBarSize,
    setLoading,
    setSuccess,
    updateProfile,
} from "../actions";
import { useManaWallet } from "../custom-providers/useWallet";
import { useWalletConnectorSelector } from "@concordium/react-components";
import { getData } from "../apis";
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { ethers } from "ethers";
import { microCcdToCcd } from "../services/concordium.service";
import ContractBinanceService from "../services/binance.service";
import MetamaskService from "../services/metamask.service";
import detectEthereumProvider from "@metamask/detect-provider";
import { useDisconnect, useActiveWallet } from "thirdweb/react";
import axios from "axios";
const CONNECTION_TYPE = "BrowserWallet"; // "WalletConnect";

const NavBar = ({ user, navbarSize }) => {
    const graphqlQuery = (address) => {
        return {
            query: `
      query ($address: String!) {
        accountByAddress(accountAddress: $address) {
          amount
        }
      }
    `,
            variables: {
                address: `${address}`,
            },
        };
    };

    const dispatch = useDispatch();
    const [modalNotifications, setModalNotifications] = useState(false);
    const [respNotifications, setrespNotifications] = useState([]);
    const [newNotify, setNewNotify] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCopySuccess, setIsCopySuccess] = useState(false);
    const [fileUrl, setFileUrl] = useState(null);
    const [autosave, setAutoSave] = useState(false);

    const {
        coinToken,
        walletAddress,
        walletBalance,
        CDCWalletProps,
        isWalletConnected,
        hasCDC,
        setHasCDC,
        setCoinToken,
        setWalletAddress,
        setWalletBalance,
        setIsWalletConnected,
        setWalletConnectionActive,
        setBinanceContract,
        displayCoinToken,
    } = useManaWallet();

    const { disconnect } = useDisconnect();
    const wallet = useActiveWallet();

    const imgRef = useRef();
    const processImage = (event) => {
        const imageFile = event.target.files[0];
        if (imageFile !== undefined) {
            setFormUpdateUser({
                ...FormUpdateUser,
                urlPhoto: event.target.files[0],
            });
            const imageUrl = URL.createObjectURL(imageFile);
            setFileUrl(imageUrl);
        }
        if (autosave) {
            ChangePhoto(event.target.files[0]);
            setAutoSave(false);
        }
    };
    const sendPictures3 = async (urlPhoto) => {
        dispatch(setLoading(true));
        try {
            if (urlPhoto !== null) {
                await S3.sendImage(urlPhoto, function (data) {
                    if (data) {
                        updateUserInformation(data.Location);
                    }
                });
            } else {
                if (
                    (FormUpdateUser.urlPhoto.length !== 0) &
                    (FormUpdateUser.urlPhoto !== undefined)
                ) {
                    await S3.sendImage(
                        FormUpdateUser.urlPhoto,
                        function (data) {
                            if (data) {
                                updateUserInformation(data.Location);
                            }
                        }
                    );
                }
            }
        } catch (error) {
            dispatch(setError("Something went wrong uploading the image."));
        }
    };
    const ChangePhoto = (urlPhoto = null) => {
        if (FormUpdateUser.urlPhoto !== "") {
            sendPictures3(urlPhoto);
        }
    };

    const [FormUpdateUser, setFormUpdateUser] = useState({
        imgUser: user.urlPhoto,
    });

    const updateUserInformation = async (urlImages3) => {
        dispatch(setLoading(true));
        const respupdateUserInformation = await userService.updateUser(
            user._id,
            FormUpdateUser,
            urlImages3 ? urlImages3 : ""
        );
        if (respupdateUserInformation.success) {
            dispatch(updateProfile(respupdateUserInformation.data.data));
            dispatch(setSuccess("Updated Player Profile"));
        } else {
            dispatch(setError(respupdateUserInformation.data.message));
        }
    };

    //Metamask Start
    const connectMM = async (isZk = false) => {
        try {
            const providerMetamask = await detectEthereumProvider();
            if (providerMetamask) {
                const metamaskWallet = await MetamaskService.getWalletData(
                    providerMetamask
                );
                // This commented part is for dev and prod, if uncommented, please comment the other if conditional
                if (isZk) {
                    if (metamaskWallet.chainId !== 324) {
                        await MetamaskService.changeChainIdToZKSYNC(
                            providerMetamask
                        );
                    }
                } else {
                    if (
                        metamaskWallet.chainId !== 56 ||
                        metamaskWallet.chainId !== 97
                    ) {
                        await MetamaskService.changeChainId(providerMetamask);
                    }
                    // if (metamaskWallet.chainId !== 97) {
                    //   await MetamaskService.changeChainId(providerMetamask)
                    // }
                }
                setWalletBalance(metamaskWallet.balance);
                setWalletAddress(metamaskWallet.address);
                setIsWalletConnected(true);
                //Create Contract using Binance Services
                const library = new Web3Provider(providerMetamask);
                setBinanceContract(new ContractBinanceService("BNB", library));
            } else {
                AlertUser.walletExtentionNotFound(
                    "Metamask",
                    "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=es"
                );
                setCoinToken("MNG");
            }
        } catch (error) {
            console.log("Error connecting to MetaMask:", error);
        }
    };

    const disconnectMM = async () => {
        try {
            try {
                await window.ethereum.request({
                    method: "wallet_revokePermissions",
                    params: [
                        {
                            eth_accounts: {},
                        },
                    ],
                });
            } catch (error) {
                console.error("Error revoking wallet permissions:", error);
            }
        } catch (error) {
            console.error("Error disconnecting from MetaMask:", error);
        }
    };
    //Metamask End

    //CDC Logic Start
    const { isSelected, isConnected, select } = useWalletConnectorSelector(
        CONNECTION_TYPE,
        CDCWalletProps
    ); // "WalletConnect"

    const connectCDC = useCallback(async () => {
        await CDCWalletProps.connectActive();
    }, [isSelected]);

    const disconnectCDC = useCallback(async () => {
        if (isConnected) {
            await CDCWalletProps.disconnectActive();
            setIsWalletConnected(false);
        }
    }, [isConnected]);
    //CDC Logic End

    //MNG Logic Start
    const connectMG = React.useCallback(async () => {
        const resp = await getData("/v1/wallet?coinToken=MNG");
        if (resp) {
            setWalletAddress(resp.data.address);
            setWalletBalance(parseFloat(resp.data.balance).toFixed(2));
            setIsWalletConnected(true);
        }
    });
    //MNG Logic End

    //BNB Logic Start
    const context = useWeb3React();
    const { connector } = context;

    async function connectBNB() {
        try {
            await connector.activate(97); // chain id
            getAccount();
        } catch (error) {
            if (
                error.message ===
                "No BSC provider was found on window.BinanceChain."
            ) {
                AlertUser.walletExtentionNotFound(
                    "Binance Smart Chain",
                    "https://chrome.google.com/webstore/detail/binance-wallet/fhbohimaelbohpjbbldcngcnapndodjp"
                );
                setCoinToken("MNG");
            } else {
                console.error(error.message);
            }
        }
    }

    async function getAccount() {
        setWalletAddress(await connector.getAccount());
        const provider = await connector.getProvider();
        const library = new Web3Provider(provider);
        const signer = library.getSigner();
        const balance = BigInt(await signer.getBalance());
        setWalletBalance(parseFloat(ethers.formatEther(balance), 2).toFixed(2));
        setIsWalletConnected(true);
        setBinanceContract(new ContractBinanceService("BNB", library));
    }
    async function disconnectBNB() {
        await connector.deactivate();
        setIsWalletConnected(false);
    }
    //BNB Logic End

    const openModalNotifications = async () => {
        setModalNotifications(true);
    };
    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleTokenSelect = (token) => {
        setCoinToken(token);
        setIsModalOpen(false);
        //Not much to see here, can I write this somewhere else?
    };

    //This function get the notifications that friendService.getNotifications sends.
    const getNoti = async () => {
        let res = await friendService.getNotifications();

        if (res.success) {
            const modifiedResults = res.data.results.map((notification) => {
                return { ...notification };
            });
            const filteredArray = modifiedResults.filter((item) => {
                return item.toUserId === user._id;
            });
            setrespNotifications(filteredArray);
            const allViewed = filteredArray.every(
                (notification) => notification.view === true
            );
            setNewNotify(!allViewed);
        }
    };

    const copyToClipboard = () => {
        navigator.clipboard
            .writeText(walletAddress)
            .then(() => {
                setIsCopySuccess(true);
                setTimeout(() => {
                    setIsCopySuccess(false);
                }, 1500);
            })
            .catch((error) => {
                // Handle error, if any.
            });
    };

    const tokenOptions = [
        { name: "Mana Games ($MNG)", value: "MNG" },
        { name: "Binance Smart Chain ($BNB)", value: "BNB" },
        { name: "Concordium ($CCD)", value: "CDC" },
        { name: "Metamask ($BNB)", value: "MM" },
        { name: "Metamask ($ZKsync)", value: "ZK" },
    ];

    useEffect(() => {
        if (!isSelected) {
            select();
        }
    }, []);
    useEffect(() => {
        //Exclusive CDC
        if (
            CDCWalletProps.activeConnectorError ===
            "Browser Wallet extension not detected"
        ) {
            setHasCDC(false);
        }
        if (CDCWalletProps.isConnecting) {
        }
        if (isConnected) {
            const { activeConnection } = CDCWalletProps;
            setWalletConnectionActive(activeConnection);
            // GET BALANCE
            if (CONNECTION_TYPE === "WalletConnect") {
                const { activeConnection } = CDCWalletProps;
                const query = graphqlQuery(
                    CDCWalletProps.activeConnectedAccount
                );
                axios
                    .post(
                        "https://api-ccdscan.mainnet.concordium.software/graphql",
                        query
                    )
                    .then((response) => {
                        const balance = microCcdToCcd(
                            response.data.data.accountByAddress.amount
                        );
                        setWalletAddress(CDCWalletProps.activeConnectedAccount);
                        setIsWalletConnected(true);
                        setWalletBalance(parseFloat(balance, 2).toFixed(2));
                        setWalletConnectionActive(activeConnection);
                    })
                    .catch((error) =>
                        console.error("Error fetching data:", error)
                    );
            } else if (CONNECTION_TYPE === "BrowserWallet") {
                const { activeConnection } = CDCWalletProps;
                const query = graphqlQuery(
                    CDCWalletProps.activeConnectedAccount
                );
                axios
                    .post(
                        "https://api-ccdscan.mainnet.concordium.software/graphql",
                        query
                    )
                    .then((response) => {
                        const balance = microCcdToCcd(
                            BigInt(response.data.data.accountByAddress.amount)
                        );
                        setWalletAddress(CDCWalletProps.activeConnectedAccount);
                        setIsWalletConnected(true);
                        setWalletBalance(parseFloat(balance, 2).toFixed(2));
                        setWalletConnectionActive(activeConnection);
                    })
                    .catch((error) => {
                        console.error("Error fetching data:", error);
                    });
            }
        }
    }, [isConnected, CDCWalletProps]);

    useEffect(() => {
        //Update Wallet Data Here.
        switch (coinToken) {
            case "CDC":
                disconnectBNB();
                disconnectMM();
                connectCDC();
                //Walkway out of hell
                if (!hasCDC) {
                    AlertUser.walletExtentionNotFound(
                        "Concordium",
                        "https://chrome.google.com/webstore/detail/concordium-wallet/mnnkpffndmickbiakofclnpoiajlegmg"
                    );
                    setCoinToken("MNG");
                }
                //Walkway out of Hell
                break;
            case "MNG":
                disconnectBNB();
                disconnectCDC();
                disconnectMM();
                connectMG();
                break;
            case "BNB":
                disconnectCDC();
                disconnectMM();
                connectBNB();
                break;
            case "MM":
                disconnectBNB();
                disconnectCDC();
                connectMM();
                break;
            case "ZK":
                disconnectBNB();
                disconnectCDC();
                connectMM(true);
                break;
            default:
                break;
        }
        getNoti();
    }, [coinToken]);

    useEffect(() => {
        //Ask Osmany Here about bnb
    }, [isWalletConnected]);

    return (
        <>
            {/*----------------------------------------NAV-BAR---------------------------------------*/}
            <nav className="navbar navbar-expand-lg navbar-dark top-bar">
                <div className="container-fluid cont">
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasExample"
                        aria-controls="offcanvasExample"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="top-bar-center-logo"> </div>
                    <div
                        className="collapse navbar-collapse box "
                        id="navbarSupportedContent"
                    >
                        <div className="col-md-8   left-container">
                            <div className="top-bar-left-button">
                                <div className="top-bar-icon">
                                    <div
                                        onClick={() =>
                                            dispatch(
                                                setNavBarSize(
                                                    navbarSize === "small"
                                                        ? "expand"
                                                        : "small"
                                                )
                                            )
                                        }
                                    >
                                        <Home height={40} width={40} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div></div>
                        {/* Do not touch this line, it can and will damage the navbar */}
                        <div className="col-md-4  rigth-container">
                            <div className="top-bar-right-info">
                                {!isWalletConnected ? (
                                    <div className="connect-button">
                                        Connecting...
                                    </div>
                                ) : (
                                    <div className="d-flex">
                                        <div
                                            className={`wallet-address ${
                                                isCopySuccess ? "success" : ""
                                            }`}
                                            onClick={copyToClipboard}
                                        >
                                            {walletAddress.slice(0, 6) +
                                                "..." +
                                                walletAddress.slice(-4)}
                                            <FileCopyOutlinedIcon className="clipboard-icon" />
                                        </div>
                                        <div
                                            className="wallet-balance"
                                            onClick={toggleModal}
                                        >
                                            {walletBalance}{" "}
                                            {displayCoinToken(coinToken)}
                                            <ArrowDropDownIcon />
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* START MODAL TOKEN*/}
                            {isModalOpen && (
                                <Modal
                                    isOpen={isModalOpen}
                                    toggle={toggleModal}
                                >
                                    <ModalHeader toggle={toggleModal}>
                                        Connect your wallet
                                    </ModalHeader>
                                    <div className="modal-text">
                                        Welcome to Mana Games, connect your
                                        wallet to continue enjoying our services
                                    </div>
                                    <ModalBody className="modal-body">
                                        {tokenOptions.map((option) => (
                                            <div
                                                key={option.value}
                                                className={`modal-option ${
                                                    coinToken === option.value
                                                        ? "selected"
                                                        : ""
                                                }`}
                                            >
                                                <img
                                                    src={require(`../assets/icon_wallet_modal_${option.value}.png`)}
                                                    alt={" "}
                                                />
                                                <span>{option.name}</span>
                                                <button
                                                    className="modal-button"
                                                    onClick={() =>
                                                        handleTokenSelect(
                                                            option.value
                                                        )
                                                    }
                                                >
                                                    {coinToken === option.value
                                                        ? "Selected"
                                                        : "Select"}
                                                </button>
                                            </div>
                                        ))}
                                    </ModalBody>
                                </Modal>
                            )}
                            {/* END MODAL TOKEN*/}
                            <div className="top-bar-rigth-button-icon">
                                <div className="top-bar-icon">
                                    <NotificationsActiveOutlinedIcon
                                        onClick={openModalNotifications}
                                    />
                                    {newNotify ? (
                                        <div className="Circle1"></div>
                                    ) : null}
                                    {modalNotifications === true ? (
                                        <Notifications
                                            data={respNotifications}
                                            error={() =>
                                                dispatch(
                                                    setError(
                                                        "Something went wrong. Please try again"
                                                    )
                                                )
                                            }
                                            update={() => getNoti()}
                                            close={async (state) => {
                                                setModalNotifications(state);
                                                await friendService.markAsSeenAll();
                                                await getNoti();
                                                setNewNotify(false);
                                            }}
                                        />
                                    ) : null}
                                </div>
                            </div>
                            <UncontrolledDropdown>
                                <DropdownToggle className="DropdownTogglestyles">
                                    {/* HERE IS THE WALLET DATA SHOWING, DON'T LOSE IT */}
                                    <img
                                        src={
                                            user.urlPhoto ? user.urlPhoto : User
                                        }
                                        className="top-bar-rigth-button-image"
                                        alt="userPicture"
                                    />
                                    <div className="top-bar-rigth-button-text">
                                        Hi, {user.userName}
                                    </div>
                                    <ArrowDown />
                                </DropdownToggle>
                                <DropdownMenu className="colormenu">
                                    <DropdownItem className="colormenu">
                                        <input
                                            id="photo"
                                            type="file"
                                            style={{ display: "none" }}
                                            ref={imgRef}
                                            onChange={processImage}
                                            accept="image/*"
                                        />
                                        <i
                                            className="ChangePasswordItem"
                                            onClick={() => {
                                                if (fileUrl === null) {
                                                    try {
                                                        imgRef.current.click();
                                                    } catch (error) {
                                                        console.error(error);
                                                    }

                                                    setAutoSave(true);
                                                } else {
                                                    ChangePhoto();
                                                }
                                            }}
                                        >
                                            Change Profile Picture
                                        </i>
                                    </DropdownItem>
                                    {/* <DropdownItem className="colormenu">
                    <Link to="/ChangePassword">
                      <i className="ChangePasswordItem">Change Password</i>
                    </Link>
                  </DropdownItem> */}
                                    <DropdownItem className="colormenu">
                                        <i
                                            className="ChangePasswordItem"
                                            onClick={() => disconnect(wallet)}
                                        >
                                            Log Out
                                        </i>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    </div>
                    <button
                        className="navbar-toggler  "
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <i className="fas fa-caret-down"></i>
                    </button>
                </div>
            </nav>
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        navbarSize: state.alerts.navbarSize,
    };
};
export default connect(mapStateToProps)(NavBar);
