import React from 'react';
import ChangePassword from '../components/Change-Password';
import NavBar from "../components/NavBar";
import SideBar from "../components/SideBar";


const ChangePasswordPage = () => {
    return (
        <>
             {/*------- NAV-BA------- */}
      <NavBar />
      {/*------- NAV-BA------- */}

      {/*------- left-bar ------- */}
      <SideBar />
      {/*------- left-bar ------- */}
      {/*------- Page-Content ------- */}
      <ChangePassword />
      {/*------- Page-Content ------- */}
        </>
    )
}

export default ChangePasswordPage
