/* global BigInt */
import { toBuffer, CcdAmount, AccountTransactionType } from '@concordium/web-sdk';
import {
    HttpProvider, JsonRpcClient, deserializeReceiveReturnValue, serializeUpdateContractParameters
} from '@concordium/web-sdk';
import { RAW_SCHEMA_BASE64, MAX_CONTRACT_EXECUTION_ENERGY, JSON_RPC_URL } from '../setting-concordium';
import moment from "moment-timezone";
import challengeService from "./challenge.service";
const TESTNET_INDEX_CONTRACT = 4143;
const CONTRACT_INDEX = TESTNET_INDEX_CONTRACT;
const RPC = new JsonRpcClient(new HttpProvider(JSON_RPC_URL));

function ISODateString(d) {
    function pad(n) { return n < 10 ? '0' + n : n }
    return d.getUTCFullYear() + '-'
        + pad(d.getUTCMonth() + 1) + '-'
        + pad(d.getUTCDate()) + 'T'
        + pad(d.getUTCHours()) + ':'
        + pad(d.getUTCMinutes()) + ':'
        + pad(d.getUTCSeconds()) + 'Z'
}
const methods = {
    acceptChallenge: "ManaChallenge.accept_challenge",
    addChallenge: "ManaChallenge.add_challenge",
    cancelChallenge: "ManaChallenge.cancel_challenge",
    setPercentageFees: "ManaChallenge.set_percentage_fees",
    setWinnerAPI: "ManaChallenge.set_winner_api",
    setWinnerPlayer: "ManaChallenge.set_winner_player",
    setWinnerValidator: "ManaChallenge.set_winner_validator",
    viewChallenge: "ManaChallenge.view_challenge",
    viewPercentagesFees: "ManaChallenge.view_percentage_fees",
    viewValidation: "ManaChallenge.view_validation"
};
const errors = {
    "-1": "You are not owner of this challenge", // NotOwner,                           // start from 1
    "-2": "Sender is not account, contact to support", // SenderIsNotAccount,                 // 2
    "-3": "Param incorrect, contact to support", // ParamIncorrect,                     // 3
    "-4": "The amount cannot to be less or equal to zero", // AmountHasToBeGreaterZero,           // 4
    "-5": "Amount does not match, check your balance", // AmountHasToBeSame,                  // 5
    "-6": "This challenge is private only opponents can accept it", // NotAllowedAcceptPrivateChallenge,   // 6
    "-7": "Private challenge need opponents, contact to support", // PrivateChallengeNeedOpponents,      // 7
    "-8": "Public challenge needs opponents defined, contact to support", // PublicChallengeRequireMaxOpponents, // 8
    "-9": "Max Opponents exceed, contact to support",  // MaxOpponentsExceeds,                // 9
    "-10": "New challenge doest not can defined opponents", // NewChallengeShallNotWinnerDefined,  // 10
    "-11": "Validator needs address ", // ValidatorNeedAddress,               // 11
    "-12": "Validator needs amount", // ValidatorNeedAmount,                // 12
    "-13": "Challenge not found", // ChallengeNotFound,                  // 13
    "-14": "Validation not found", // ValidationNotFound,                 // 14
    "-15": "Challenge already exist with that id", // ChallengeAlreadyExist,              // 15
    "-16": "Challenge is already finish", // ChallengeIsOver,                    // 16
    "-17": "Results can only be entered after the challenge date and time", // ChallengeIsNotExpired,              // 17
    "-18": "You are not part of this challenge", // YouAreNotChallenger,                // 18
    "-19": "Challenge is active", // ChallengeIsActive,                  // 19
    "-20": "Winner not is part of this challenge", // WinnerNotExists,                    // 20
    "-21": "Transfer error to owner", // TransferErrorToOwner,               // 21
    "-22": "Transfer error to winner", // TransferErrorToWinner,              // 22
    "-23": "Transfer error", // TransferError,                      // 23
    "-24": "This opponent did not accept this challenge", // OpponentDidNotAccept,               // 24
    "-25": "This account is not part of this chalenge", // AccountIsNotPartOf,                 // 25
}

export async function getContractInfo() {
    const info = await RPC.getInstanceInfo({ index: CONTRACT_INDEX, subindex: BigInt(0) });
    if (!info) {
        throw new Error(`contract ${CONTRACT_INDEX} not found`);
    }
    return info;
}

export async function waitForSuccessTransaction(txHash, challengeId = "") {
    // we have to wait for the transaction to be completed
    let result = { status: "" };
    while (result.status !== "finalized") {
        result = await getStatusTransaction(txHash);
        // we have to wait for the transaction is rejected
        if (result.status === "rejected") {
            throw new Error("Transaction rejected");
        }
        // wait two seconds
        if (challengeId !== "") {
            await challengeService.updateStatusBlockchainChallenge(result.status, result, challengeId);
        }
        await new Promise((resolve) => setTimeout(resolve, 1000));
    }
    if (challengeId !== "") {
        await challengeService.updateStatusBlockchainChallenge(result.status, result, challengeId);
    }
    // check if outcome is success
    const key = Object.keys(result.outcomes)[0];
    if (result.outcomes[key].result.outcome !== "success") {
        const codeReason = result.outcomes[key].result.rejectReason.rejectReason;
        throw new Error(errors[codeReason.toString()]);
    }
}

export async function getFees(payload) {
    const method = methods.viewPercentagesFees;
    const result = await RPC.invokeContract({
        contract: { index: CONTRACT_INDEX, subindex: BigInt(0) },
        method,
        parameter: payload ? payloadToJson(payload) : null,
    });
    if (!result) {
        throw new Error(`invocation of method "${method}" on contract "${CONTRACT_INDEX}" returned no result`);
    }
    const buffer = toBuffer(result.returnValue || '', 'hex');
    const [state] = decodeByte(buffer, 0);
    return state;
}

export async function findChallenge(challengeId) {
    const payload = {
        "challenge_id": challengeId
    };
    const method = methods.viewChallenge;
    const result = await RPC.invokeContract({
        contract: { index: CONTRACT_INDEX, subindex: BigInt(0) },
        method,
        parameter: payloadToJson(method, payload),
    });
    if (result.tag === 'failure') {
        const codeReason = result.reason.rejectReason;
        throw new Error(errors[codeReason.toString()]);
    }
    const challenge = decodeChallenge(method, result);
    return challenge;
}

export async function getValidation(challengeId) {
    const payload = {
        "challenge_id": challengeId
    };
    const method = methods.viewValidation;
    const result = await RPC.invokeContract({
        contract: { index: CONTRACT_INDEX, subindex: BigInt(0) },
        method,
        parameter: payloadToJson(method, payload),
    });
    if (!result) {
        throw new Error(`invocation of method "${method}" on contract "${CONTRACT_INDEX}" returned no result`);
    }
    const challenge = decodeValidation(method, result);
    return challenge;
}

export async function createChallenge(sender, client, challenge) {
    const method = methods.addChallenge;
    const amount = ccdToMicroCcd(challenge.amount);
    const payload = {
        "challenge_id": challenge._id,
        "challenge": {
            amount: amount.toString(),
            "game": challenge.game.gameName,
            "opponents": {
                "None": []
            },
            "challenger": sender,
            "expiration": moment(challenge.startDate).format("YYYY-MM-DDTHH:mm:ssZ"),
            "is_public": true,
            "max_opponents": {
                "Some": [Number(challenge.challenged.length || challenge.maxPlayersInPublicChallenge)]
            }
        }
    };
    console.dir(payload);
    const txHash = await client.sendTransaction(
        sender,
        AccountTransactionType.Update,
        {
            amount: new CcdAmount(amount),
            address: { index: CONTRACT_INDEX.toString(), subindex: BigInt(0) },
            receiveName: method,
            maxContractExecutionEnergy: MAX_CONTRACT_EXECUTION_ENERGY
        },
        payload,
        RAW_SCHEMA_BASE64,
    );
    await waitForSuccessTransaction(txHash, challenge._id);
    return txHash;
}

export async function cancelChallenge(sender, client, challengeId) {
    const method = methods.cancelChallenge;
    const payload = {
        "challenge_id": challengeId
    };
    const txHash = await client.sendTransaction(
        sender,
        AccountTransactionType.Update,
        {
            amount: new CcdAmount(BigInt(0)),
            address: { index: CONTRACT_INDEX.toString(), subindex: BigInt(0) },
            receiveName: method,
            maxContractExecutionEnergy: MAX_CONTRACT_EXECUTION_ENERGY
        },
        payload,
        RAW_SCHEMA_BASE64,
    );
    await waitForSuccessTransaction(txHash);
    return txHash;
}

export async function acceptChallenge(sender, client, amount, challengeId) {
    const method = methods.acceptChallenge;
    const payload = {
        "challenge_id": challengeId
    };
    amount = ccdToMicroCcd(amount);
    const txHash = await client.sendTransaction(
        sender,
        AccountTransactionType.Update,
        {
            amount: new CcdAmount(amount),
            address: { index: CONTRACT_INDEX.toString(), subindex: BigInt(0) },
            receiveName: method,
            maxContractExecutionEnergy: MAX_CONTRACT_EXECUTION_ENERGY
        },
        payload,
        RAW_SCHEMA_BASE64,
    );
    await waitForSuccessTransaction(txHash);
    return txHash;
}

export async function setWinnerPlayer(sender, client, addressWinner, challengeId) {
    const method = methods.setWinnerPlayer;
    const payload = {
        "challenge_id": challengeId,
        "winner": addressWinner,
        "validator_address": {
            "None": []
        },
        "validator_amount": {
            "None": []
        }
    }
    const txHash = await client.sendTransaction(
        sender,
        AccountTransactionType.Update,
        {
            amount: new CcdAmount(BigInt(0)),
            address: { index: CONTRACT_INDEX.toString(), subindex: BigInt(0) },
            receiveName: method,
            maxContractExecutionEnergy: MAX_CONTRACT_EXECUTION_ENERGY
        },
        payload,
        RAW_SCHEMA_BASE64,
    );
    await waitForSuccessTransaction(txHash);
    return txHash;
}

export function microCcdToCcdString(amount) {
    const int = amount / BigInt(1e6);
    const frac = amount % BigInt(1e6);
    return `${int}.${frac.toString().padStart(6, '0')}`;
}

export function microCcdToCcd(amount) {
    return Number(microCcdToCcdString(amount)).toFixed(2);
}

export function getStatusTransaction(hash) {
    return RPC.getTransactionStatus(hash);
}

export function ccdToMicroCcd(amount) {
    const [int, frac] = parseFloat(amount).toFixed(2).split('.');
    return BigInt(int) * BigInt(1e6) + BigInt(frac.padEnd(6, '0'));
}

export function decodeByte(buffer, offset) {
    return [buffer.readUInt8(offset), offset + 1];
}

export function payloadToJson(method, payload) {
    const [contractName, methodName] = method.split('.')
    return serializeUpdateContractParameters(contractName, methodName, payload, toBuffer(RAW_SCHEMA_BASE64, 'base64'))
}

export function decodeChallenge(method, resultInvoke) {
    const [contractName, methodName] = method.split('.')
    const returnBytes = toBuffer(resultInvoke.returnValue, 'hex');
    const schema = toBuffer(RAW_SCHEMA_BASE64, 'base64');
    return deserializeReceiveReturnValue(returnBytes, schema, contractName, methodName);
}

export function decodeValidation(method, resultInvoke) {
    const [contractName, methodName] = method.split('.')
    const returnBytes = toBuffer(resultInvoke.returnValue, 'hex');
    const schema = toBuffer(RAW_SCHEMA_BASE64, 'base64');
    return deserializeReceiveReturnValue(returnBytes, schema, contractName, methodName);
}