import { ethers } from "ethers";
import { formatBytes32String, } from "@ethersproject/strings"
const ChallengeBNB = require("../assets/contracts/ChallengeBNB.json");
const addressTestnet = "0x5072f75CCCa2beB3a6DC8CaD561069e2bEc53a80";
const addressMainnet = "0x8B1c8F9EB9004F423B5B3820b9eACc4Be17c930C";
const networkOfTheSite = window.location.host;
const address = networkOfTheSite === "app.managames.io" ? addressMainnet : addressTestnet;

export default class ContractBinanceService {
    #provider = null;
    #contract = null;
    #status = {
        0: "Pending",
        1: "Accepted",
        2: "Playing",
        3: "Finished",
        4: "Disputed",
        5: "Canceled",
    };
    coinToken = "";
    constructor(coinToken, provider) {
        this.coinToken = coinToken;
        this.#provider = provider;
        this.#initializeContract();
    }

    #getContractDetails() {
        switch (this.coinToken) {
            case "BNB":
                return ChallengeBNB;
            default:
                return null;
        }
    }

    set provider(provider) {
        this.#provider = provider;
    }

    #initializeContract() {
        const { abi } = this.#getContractDetails();
        this.#contract = new ethers.Contract(
            address,
            abi,
            this.#provider.getSigner()
        );
    }

    async #waitTransaction(promiseResponse, errStringOnFail) {
        try {
            const tx = await promiseResponse;
            const receipt = await this.#provider.waitForTransaction(tx.hash);
            if (receipt.status === 0) {
                throw new Error(errStringOnFail);
            }
            return receipt;
        } catch (err) {
            throw new Error(errStringOnFail);
        }
    }

    async createChallenge(
        _id,
        name,
        game,
        amount,
        startTime,
        acceptors,
        maximumAcceptors
    ) {
        // name param has to have less or equal 64 bits
        if (new Blob([name]).size > 64) {
            throw new Error("Name has to have less characters");
        }
        const utcTimeStamp = Math.floor(startTime.getTime() / 1000);
        const estimateGas = await this.#provider.estimateGas({
            to: address,
            data: this.#contract.interface.encodeFunctionData("createChallenge", [
                this.#stringToBytes32(_id),
                this.#stringToBytes32(game),
                this.#stringToBytes(name),
                ethers.parseEther(amount),
                utcTimeStamp,
                acceptors,
                maximumAcceptors,
            ]),
            value: ethers.parseEther(amount),
        });
        const gasLimit = estimateGas.mul(2);
        const tx = this.#contract.createChallenge(
            this.#stringToBytes32(_id),
            this.#stringToBytes32(game),
            this.#stringToBytes(name),
            ethers.parseEther(amount),
            utcTimeStamp,
            acceptors,
            maximumAcceptors,
            {
                gasLimit: gasLimit.toNumber(),
                estimateGas: estimateGas.toNumber(),
                value: ethers.parseEther(amount),
            }
        );
        return await this.#waitTransaction(tx, "Create challenge failed");
    }

    async getChallenge(_id) {
        const challenge = await this.#contract.getChallenge(
            this.#stringToBytes32(_id)
        );
        const challengeData = challenge.toObject();
        if (challengeData.creator === ethers.ZeroAddress) {
            throw new Error("Challenge not found");
        }
        challengeData.status = this.#status[challengeData.status];
        return challengeData;
    }

    async cancelChallenge(_id) {
        const tx = this.#contract.cancelChallenge(this.#stringToBytes32(_id), {
            value: ethers.parseEther("0.0"),
        });
        return await this.#waitTransaction(tx, "Cancel challenge failed");
    }

    async acceptChallenge(_id, amount) {
        const tx = this.#contract.acceptChallenge(
            this.#stringToBytes32(_id),
            {
                value: ethers.parseEther(amount),
            }
        );
        return await this.#waitTransaction(tx, "Accept challenge failed");
    }

    async setResultPlayer(_id, winnerAddress) {
        const tx = this.#contract.setResultPlayer(
            this.#stringToBytes32(_id),
            winnerAddress,
            {
                value: ethers.parseEther("0.0"),
            }
        );
        return await this.#waitTransaction(tx, "Set result failed");
    }

    async getAcceptor(_id, acceptorAddress) {
        const acceptor = await this.#contract.getAcceptor(
            this.#stringToBytes32(_id),
            acceptorAddress
        );
        const acceptorData = acceptor.toObject();
        if (acceptorData.address === ethers.ZeroAddress) {
            throw new Error("Acceptor not found");
        }
        return acceptorData;
    }

    #stringToBytes32(str) {
        return formatBytes32String(str);
    }

    #stringToBytes(str) {
        const utf8Encode = new TextEncoder();
        return utf8Encode.encode(str);
    }
}