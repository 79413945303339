export const items = [
    {
        name: "Elemental Pass ($5)",
        description: "The Elemental Pass provides an additional 1% boost to all Quests, grants access to the League Quests, and early access to Social Esports.",
        image: "https://d1790zh956hv5i.cloudfront.net/nft/wizard_purple.png",
        coinbaseUrl: "https://commerce.coinbase.com/checkout/cf6726cc-4a6c-4b31-8618-f86fe287bc71"
    },
    {
        name: "Fabled Pass ($10)",
        description: "The Fabled Pass provides an additional 2% rewards boost to all Quests, further access to the Mana Partner Network, and eligibility to receive the End of Season Treasure Chest.",
        image: "https://d1790zh956hv5i.cloudfront.net/nft/wizard_blue_side.png",
        coinbaseUrl: "https://commerce.coinbase.com/checkout/9f56ed5f-8f5e-4eb5-ad02-f9d7894c9b59"
    },
    {
        name: "Mythic Pass ($20)",
        description: "The Mythic Pass provides an additional 3% rewards boost to all Quests, full access to all Mana features and Partner Network, and eligibility to receive End of Season Treasure Chest.",
        image: "https://d1790zh956hv5i.cloudfront.net/nft/wizard_green.png",
        coinbaseUrl: "https://commerce.coinbase.com/checkout/9b317f2e-0c0f-429b-a774-a89e31c05929"
    },
    {
        name: "Power up Potion ($5)",
        description: "This potion provides the user with an additional 2% rewards boost on top of your Passport rewards boost. ",
        image: "https://d1790zh956hv5i.cloudfront.net/nft/mana_bottle_blue.png",
        coinbaseUrl: "https://commerce.coinbase.com/checkout/4a6a8801-ae0d-4416-8f8c-0ea108c2850e"
    },
]