import React, { useState } from "react";
import "../assets/styles/ChangePassword.css";
import { Auth } from "aws-amplify";
import AlertUser from "../Alerts/userAlerts/index";
import { Loading } from "../hooks/Loading";
import { connect } from "react-redux";


const ChangePassword = ({ navbarSize }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [form, setForm] = useState({
    password: "",
    oldPassword: "",
    confirmPassword: "",
  });
  const sendMail = async () => {
    if (form.password !== form.confirmPassword) {
      AlertUser.Error({ message: "Password and confirm password dont match" });
    } else {
      try {
        Loading.set(true);
        Auth.currentAuthenticatedUser()
          .then((username) => {
            Auth.changePassword(username, form.oldPassword, form.password)
              .then(() => {
                Loading.set(false);
                AlertUser.success();
                setForm({ password: "", oldPassword: "", confirmPassword: "" });
              })
              .catch((e) => {
                Loading.set(false);
                {e.message === "Incorrect username or password." ? AlertUser.Error("Wrong Current Password") :  AlertUser.Error(e) }
              });
          })
          .catch((e) => {
            Loading.set(false);
            console.log(e);
            AlertUser.Error({ message: "User not found" });
          });
      } catch (error) {
        Loading.set(false);
        AlertUser.Error({ message: "Something went wrong. Please try again" });
      }
    }
  };

  return (
    <div className={navbarSize === "small" ? "main-bg-2" : "main-bg"}>
      <div className="container" style={{marginTop:"5.375rem"}}>
        <div className="row">
          <div className="offset-lg-2 col-lg-8">
            <div className="row">
              <div className="col-12">
                <h1 className="title">Change Password</h1>
              </div>
              <div className="col-12 form">
                <div className="fields">
                  <input
                    className="form-field"
                    type={showPassword ? "text" : "password"}
                    placeholder="Current Password"
                    value={form.oldPassword}
                    onChange={({ target }) =>
                      setForm({ ...form, oldPassword: target.value })
                    }
                  />
                  <i
                    className="fas fa-eye"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  />
                </div>
                <div className="fields">
                  <input
                    className="form-field"
                    type={showPassword ? "text" : "password"}
                    placeholder="New Password"
                    value={form.password}
                    onChange={({ target }) =>
                      setForm({ ...form, password: target.value })
                    }
                  />
                  <i
                    className="fas fa-eye"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  />
                </div>
                <div className="fields">
                  <input
                    className="form-field"
                    type={showPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    value={form.confirmPassword}
                    onChange={({ target }) =>
                      setForm({ ...form, confirmPassword: target.value })
                    }
                  />
                  <i
                    className="fas fa-eye"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  />
                </div>
                <button className="btn--primary" onClick={sendMail}>
                  Change
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    navbarSize: state.alerts.navbarSize,
  };
};
export default connect(mapStateToProps)(ChangePassword);

